<ion-app><!--
  <ion-split-pane>
    <ion-menu>
      <ion-header>
        <ion-toolbar [color]="color">
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
            <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    
  </ion-split-pane>-->
  <ion-router-outlet main></ion-router-outlet>
  <!--<app-spinner *ngIf="spinnerService.isLoading"></app-spinner>-->
</ion-app>


<div
class="orientation-popup"
  style="position:absolute; top:0; left:0; right:0; bottom:0; z-index: 99999; background-color: #000000cc">

  <div
    style="position:absolute; top:50%; left:50%; z-index: 99999; background-color: white; border-radius: 25px; transform: translate(-50%,-50%);">

    <img src="/assets/imgs/rotate.gif" style="max-height: 200px; filter: invert(1); display: block; margin:auto">

  </div>
 
</div>