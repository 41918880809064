import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreviewService {

  public offsetX = 0

  private data;
  private type;
  private completed = false;
  private gyro: boolean = false;

  private index: number = null;

  private geo: GeolocationPosition = null;
  private vehType: string;

  constructor() {

 

  }

 


  setGeo(geo: GeolocationPosition) {
    this.geo = geo;
  }

  getGeo() {
    return this.geo;
  }

  setIndex(index: number) {
    this.index = index;
  }

  getIndex(): number {
    return this.index;
  }

  setData(data) {
    this.data = data
  }

  setDataResult(data) {
    this.geo = null;
    this.data = data
    this.completed = true
  }

  setType(type: string) {
    this.type = type;
  }


  getData(): any {
    return this.data
  }

  getType(): string {
    return this.type;
  }


  setDataType(data, type: string) {
    this.setData(data)
    this.setType(type)
  }

  clear() {

    if (!this.completed) return;

    console.log('clear')
    this.completed = false;
    this.data = null;
    this.type = null;
  }


  isCompleted(): boolean {
    return this.completed
  }

  setGyro(b: boolean) {
    this.gyro = b;
    console.log('set gyro: ' + this.gyro)
  }
  getGyro() {
    console.log('get gyro: ' + this.gyro)
    return this.gyro;
  }

}
