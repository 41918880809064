
import { NgModule } from '@angular/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginRouteGuard, NotLoginRouteGuard } from './route-guard';
import { MysqlService } from './services/mysql.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthorizationService } from './services/authorization.service';


import { PreviewService } from './services/preview.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicStorageModule } from '@ionic/storage';
import { getEnv } from './shared';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { PartPickerModalComponent } from './components/part-picker-modal/part-picker-modal.component';

import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

//import { Firebase } from '@ionic-native/firebase/ngx';
//import { AngularFireAuthModule } from '@angular/fire/auth/';
//import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
//import {  AngularFireFunctionsModule} from '@angular/fire/functions';

import { IonicGestureConfig } from './ionic-gesture-config'
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { AngularFireModule } from '@angular/fire';
import { BaseRouteReuseStrategy } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { UserService } from './services/user.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
//import { SpinnerInterceptor } from '../interceptors/spinner-interceptor';
//import { SpinnerComponent } from './components/spinner/spinner.component';
//import { SpinnerService } from './services/spinnerService.service';


//const config_test = { "code": "XPERT", "env": "gg", "description": "XPERT GG", "url_base": "https://xpert.ggroup.cloud/api/api.php?cmd=", "username": "matteo.bombardi@gmail.com", "firebase_config": { "apiKey": "AIzaSyBesDqcByu-aW2sEyKJ_iPFSkx9k7a2RW4", "authDomain": "xpert-gg-4aaa1.firebaseapp.com", "databaseURL": "https://xpert-gg-4aaa1.firebaseio.com", "projectId": "xpert-gg-4aaa1", "storageBucket": "xpert-gg-4aaa1.appspot.com", "messagingSenderId": "148779623923", "appId": "1:148779623923:web:73fae748294ca81a4d1e86" }, "img": "/assets/img/gg_logo.png" }.firebase_config


let config // config_test
let env = getEnv();
let envs = []



try {
  let tmp = localStorage.getItem('ENVIRONMENTs')
  console.log('envs', tmp)
  if (tmp) envs = JSON.parse(tmp);
  //config = envs.find(e => e.env == env).firebase_config
} catch (error) {
  //config = config_test
  //console.error("Firebase non configurato")
}


@NgModule({
  declarations: [AppComponent, PartPickerModalComponent,
    //SpinnerComponent
  ],
  imports: [
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      driverOrder: [CordovaSQLiteDriver._driver, 'indexeddb', 'websql']
    }),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    /*
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireFunctionsModule,*/
  ],
  providers: [
    //{ provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    //AngularFirestore,
    { provide: BaseRouteReuseStrategy, useClass: IonicRouteStrategy },
    LoginRouteGuard, NotLoginRouteGuard, MysqlService,
    //Firebase,
    AuthorizationService,
    PreviewService,
    ScreenOrientation,
    UserService,
    //SpinnerService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
  ],
  bootstrap: [AppComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})


export class AppModule {
}

