import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginRouteGuard, NotLoginRouteGuard } from './route-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [LoginRouteGuard]
  },
/*
  {
    path: 'automotive/insurance',
    loadChildren: () => import('./deprecated/insurance-list/insurance-list.module').then(m => m.InsuranceListPageModule),
    canActivate: [LoginRouteGuard]
  },
  */
  {
    path: 'automotive/firebase/insurance',
    loadChildren: () => import('./insurance-list-firebase/insurance-list.module').then(m => m.InsuranceListFirebasePageModule),
    canActivate: [LoginRouteGuard]
  },
/*
  {
    path: 'automotive/insurance/:id',
    loadChildren: () => import('./deprecated/insurance-detail/insurance-detail.module').then(m => m.InsuranceDetailPageModule),
    canActivate: [LoginRouteGuard]
  },
  */
  {
    path: 'automotive/firebase/insurance/:id',
    loadChildren: () => import('./insurance-detail-firebase/insurance-detail.module').then(m => m.InsuranceDetailFirebasePageModule),
    canActivate: [LoginRouteGuard]
  },

  {
    path: 'login/:cmd',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    //canActivate: [NotLoginRouteGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    //canActivate: [NotLoginRouteGuard]
  },
  {
    path: 'preview/:id',
    loadChildren: () => import('./preview/preview.module').then(m => m.PreviewPageModule),
    canActivate: [LoginRouteGuard]
  },

  { path: 'preview', loadChildren: () => import('./preview/preview.module').then(m => m.PreviewPageModule) },
  //{ path: 'solera', loadChildren: () => import('./solera/solera.module').then(m => m.SoleraPageModule) },
  { path: 'test', loadChildren: () => import('./test/test.module').then(m => m.TestPageModule) },
  { path: 'uploader', loadChildren: () => import('./uploader/uploader.module').then(m => m.UploaderPageModule) },
  { path: 'storage', loadChildren: () => import('./storage/storage.module').then(m => m.StoragePageModule) },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarPageModule)
  },
  {
    path: 'file-browser',
    loadChildren: () => import('./file-browser/file-browser.module').then(m => m.FileBrowserPageModule)
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { } 
